import React, { useEffect, useState } from 'react';
import Header from './../Components/Header';
import Footer from './../Components/Footer';
import Title from './../Components/Title';
import Contact from './../Components/Contact';
import SkillsSection from './../Components/SkillsSection';
import ToolsSection from './../Components/ToolsSection';
import Highlight from './../Components/Highlight';
import ParticleDiv from '../Components/ParticleDiv';
import ProjectCard from './../Components/ProjectCard';
import { FaGithub, FaLinkedin, FaYoutube } from "react-icons/fa";

const HomePage = () => {
  const [fadeIn, setFadeIn] = useState(false);
  const [section, setSection] = useState(null);

  const projects = [
    { 
      name: 'Boombox', 
      image: 'projects/boombox.png', 
      video: 'projects/boombox-bg-video.mp4',
      role: 'VR Game Programmer', 
      page: 'https://store.steampowered.com/app/1485120/BoomBox/',
      description: 'I led the development of a Unity VR game from concept to live-ops and played a vital role in creating a live and engaged community.' 
    },       
    { 
      name: 'Quaver', 
      image: 'projects/quaver.jpg', 
      video: 'projects/quaver-bg-video.mp4',
      role: 'Game Developer', 
      page: 'https://store.steampowered.com/app/980610/Quaver/',
      description: 'I developed the rendering framework and gameplay mechanics during early production, and laid out the foundation for the game\'s infrastructure.' 
    },
    { 
      name: 'Kumakult', 
      image: 'projects/kumakult.png', 
      video: 'projects/kumakult-bg-video.mp4',
      role: 'Full Stack Developer', 
      page: 'https://www.kumakult.com',
      description: 'I created a webapp in react/tailwind which features Discord integration, a bookings page, tournament organizer tools, and more.' 
    },
  ];

  const tools = [
    { name: 'Unity', image: 'logos/unity.png', years: 8 },
    { name: 'Unreal', image: 'logos/unreal.png', years: 4 },
    { name: 'C#', image: 'logos/c-sharp.png', years: 8 },
    { name: 'Python', image: 'logos/python.png', years: 4 },
    { name: 'Maya', image: 'logos/maya.png', years: 7 },
    { name: 'Monogame', image:'logos/monogame.png', years: 4},
    { name: 'Node.js', image: 'logos/node-js.png', years: 4 },
    { name: 'React', image: 'logos/react.png', years: 2 },
    { name: 'Tailwind', image: 'logos/tailwind.png', years: 2 },
    { name: 'Azure', image: 'logos/azure.png', years: 4 },
    { name: 'Git', image: 'logos/git.png', years: 8 },
    { name: 'SQL', image: 'logos/sql.png', years: 4 },
  ];

  const skills = [
    "Programming",
    "Systems Design",
    "Game Design",
    "Databases",
    "Web Development",
    "Technical Art",
    "Shaders",
    "Particle Systems",
    "3D Animation",
    "Rigging",
    "Prototyping",
    "Documentation",
    "Agile"
  ];

  const unity = [
    "VR/AR Development",
    "Android Development",
    "Entities / ECS",
    "Scriptable Objects",
    "Addressables",
    "Shadergraph",
    "HLSL",
    "Animation Graph",
    "Input System",
    "UI Toolkit",
    "URP",
    "Photon",
    "External Libraries",
    "Frame Debugging",
    "Profiler",
    "Automation",
    ".fbx / .obj",
  ];

  useEffect(() => {
    setFadeIn(false);
    
    const timer = setTimeout(() => {
      setFadeIn(true);
    }, 2000);

    const handleScroll = () => {
      setFadeIn(true);
      window.removeEventListener('scroll', handleScroll);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      clearTimeout(timer);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <main className="font-lexend">
      <section id="Welcome" className="mx-auto h-screen flex flex-col items-center justify-center border-b-2 border-black overflow-hidden">
      <video
        className={`object-cover w-full h-full transition-opacity duration-2000 ${fadeIn ? 'opacity-80' : 'opacity-0'}`}
        autoPlay
        loop
        muted
        onContextMenu={(e) => e.preventDefault()}
      >
        <source src="bg-video.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
        <div className={`absolute overflow-hidden bg-white rounded-lg mx-4 border-2 transition-color duration-2000 ${fadeIn ? 'border-black px-4 py-4 lg:px-16 lg:py-8 lg:mx-0 lg:my-0' : 'border-white lg:py-2 lg:px-4 lg:mx-12 lg:my-6'}`}>
          <h2 className="text-xs lg:text-sm text-center mb-4">Hello, my name is <span className="font-bold">Deny Senesouma.</span></h2>
          <h1 className="text-lg sm:text-2xl md:text-3xl lg:text-3xl text-center">I am a <Highlight>Programmer</Highlight> and <Highlight>3D Generalist</Highlight>.</h1>
        </div>
      </section>
      <Header setSection={setSection} />
      <ParticleDiv section={section}>
        <section id="About" className="mx-auto pt-20 mb-40 flex flex-col items-center px-4 lg:px-20 max-w-7xl">
          <Title title={"About"} />
          <div className="flex flex-col lg:flex-row gap-10">
            <div className="w-full mb-6 lg:mb-0">
              <div className="flex flex-col lg:sticky lg:top-1/3 bg-white px-6 py-6 bg-zinc-100 rounded-2xl">
                <img src="logos/avatar-4.jpg" className="h-24 w-24 mx-auto mt-2 rounded-full overflow-hidden" />
                <h3 className="text-lg mt-4 mb-2 font-bold text-zinc-800 text-center">Deny Senesouma</h3>
                <p className="max-w-lg mx-auto text-center">
                  Hello. I specialize in developing experiences across a variety of platforms. Whether it’s <Highlight>Games</Highlight>, <Highlight>Apps</Highlight>, or <Highlight>Websites</Highlight>, I am dedicated to crafting engaging and responsive interactivity.
                </p>
              </div>
            </div>
            <div className="w-full">
              <h3 className="mb-4 text-lg font-bold text-zinc-800">Favorite Tools</h3>
              <ToolsSection tools={tools} />
              <h3 className="mt-20 mb-4 text-lg font-bold text-zinc-800">Technical Skills</h3>
              <SkillsSection skills={skills} />
              <h3 className="mt-20 mb-4 text-lg font-bold text-zinc-800">Unity</h3>
              <SkillsSection skills={unity} />
            </div>
          </div>
        </section>
        <section id="Showcase" className="mx-auto flex flex-col items-center mb-20">
        <Title title={"Showcase"} />
          <div className="max-w-xl grid grid-cols-1 lg:grid-cols-1 gap-4 px-8 content-start">
            {projects.map((project) => (
              <ProjectCard key={project.name} project={project} />
            ))}
          </div>
        </section>
        <section id="Social Media" className="mx-auto flex flex-col items-center">
        <Title title={"Social Media"} />
          <h2 className="text-sm mb-4 mx-4">I don't post frequently, but feel free to check out my social media!</h2>
          <div className="flex flex-col lg:flex-row lg:flex-wrap gap-2 mb-20 mx-4 items-center">
            <a href="https://github.com/staravia" className="text-black flex gap-2 whitespace-nowrap items-center bg-white border-black border-2 rounded-lg px-4 py-1 hover:bg-zinc-100 transition">
              <span>Github</span>
              <FaGithub />
            </a>
            <a href="https://linkedin.com/in/senesouma" className="text-black flex gap-2 whitespace-nowrap items-center bg-white border-black border-2 rounded-lg px-4 py-1 hover:bg-zinc-100 transition">
              <span>LinkedIn</span>  
              <FaLinkedin />
            </a>
            <a href="https://www.youtube.com/@denysenesouma2648" className="text-black flex gap-2 whitespace-nowrap items-center bg-white border-black border-2 rounded-lg px-4 py-1 hover:bg-zinc-100 transition">
              <span>Youtube</span>  
              <FaYoutube />
            </a>
          </div>
          <div className="w-full bg-zinc-100 bg-opacity-60 py-20">
            <div className="max-w-md mx-auto">
              <Contact />
            </div>
          </div>
        </section>
      </ParticleDiv>
      <Footer />
    </main>
  );
};

export default HomePage;
