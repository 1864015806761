import React, { useEffect, useState } from 'react';
import ToolCard from './../Components/ToolCard';

const ToolsSection = ({ tools }) => {
  const [selectedToolIndices, setSelectedToolIndices] = useState([]);

  useEffect(() => {
    const selectRandomTools = () => {
      const indices = new Set();
      while (indices.size < 1) {
        indices.add(Math.floor(Math.random() * tools.length));
      }
      setSelectedToolIndices(Array.from(indices));
    };

    const interval = setInterval(selectRandomTools, 1400);

    return () => clearInterval(interval);
  }, [tools.length]);

    return (
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-3 xl:grid-cols-4 gap-2 content-start">
            {tools.map((tool, index) => (
                <ToolCard key={tool.name} tool={tool} isSelected={selectedToolIndices.includes(index)} />
            ))}
        </div>
    );
}

export default ToolsSection;