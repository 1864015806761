import React, { useState, useEffect, useRef } from 'react';
import SkillCard from './SkillCard';

const SkillsSection = ({ skills }) => {
  const [displayedSkills, setDisplayedSkills] = useState([]);
  const sectionRef = useRef(null);
  const hasRevealedSkills = useRef(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !hasRevealedSkills.current) {
          startRevealingSkills();
          hasRevealedSkills.current = true;
        }
      },
      {
        threshold: 0.7,
        rootMargin: '0px 0px -20% 0px',
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  const startRevealingSkills = () => {
    skills.forEach((skill, index) => {
      setTimeout(() => {
        setDisplayedSkills((prev) => [...prev, skill]);
      }, index * 50);
    });
  };

  return (
    <div ref={sectionRef}>
      <div className="flex flex-wrap gap-2">
        {skills.map((skill, index) => (
          <SkillCard
            key={`${skill}-${index}`}
            skill={skill}
            isVisible={displayedSkills.includes(skill)}
          />
        ))}
      </div>
    </div>
  );
};

export default SkillsSection;
