import React from 'react';

const Contact = () => {
  return (
    <div className="w-full mx-auto rounded-lg">
      <div className="w-full px-4">
        <h2 className="text-2xl font-bold mb-4">Contact</h2>
        <p className="mb-4">Reach out to me via email.</p>
        <form action="mailto:deny@senesouma.com" method="post" encType="text/plain">
          <input
            type="text"
            name="name"
            placeholder="Your Name"
            className="w-full px-2 py-1 mb-4 border border-black rounded"
            required
          />
          <input
            type="email"
            name="email"
            placeholder="Your Email"
            className="w-full px-2 py-1 mb-4 border border-black rounded"
            required
          />
          <textarea
            name="message"
            placeholder="Your Message"
            className="w-full px-2 py-1 mb-4 border border-black rounded"
            rows="5"
            required
          />
          <button
            type="submit"
            className="w-full py-2 bg-yellow-300 text-black hover:bg-yellow-400 rounded transition"
          >
            Send Email
          </button>
        </form>
      </div>
    </div>
  );
};

export default Contact;