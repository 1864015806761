import React, { useEffect, useState } from 'react';

const Header = ({setSection}) => {  
  const [activeSection, setActiveSection] = useState('Top');
  const sections = ['Welcome', 'About', 'Showcase', 'Social Media'];

  const scrollToSection = (section) => {
    const element = document.getElementById(section);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && entry.target.id) {
          setActiveSection(entry.target.id);
          setSection(entry.target);
        }
      });
    }, {
      threshold: 0.5,
      root: null,
    });

    const sections = document.querySelectorAll('section');
    sections.forEach(section => observer.observe(section));

    return () => {
      sections.forEach(section => observer.unobserve(section));
    };
  }, []);

  return (
    <header className="py-1 bg-black sticky top-0 z-10 transform">
        <div className="flex flex-wrap justify-center space-x-4">
        {sections.map((section, index) => (
            <button
            key={section}
            className={`hover:text-yellow-100 lg:text-xl py-1 transition whitespace-nowrap ${index === 0 ? 'invisible w-0 px-0 lg:px-4 lg:visible lg:w-auto' : 'px-2 lg:px-4'} ${activeSection === section ? 'text-yellow-300' : 'text-white'}`}
            onClick={() => scrollToSection(section)}
            >
            {section}
            </button>
        ))}
        </div>
    </header>
  );
};

export default Header;
