import React, { useState, useEffect, useRef } from 'react';

const ProjectCard = ({ project }) => {
  const [isHovered, setIsHovered] = useState(false);
  const cardRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const card = cardRef.current;
      if (!card) return;

      const rect = card.getBoundingClientRect();
      const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
      const middleOfViewport = viewportHeight / 2;
      const isMiddle = rect.top <= middleOfViewport && rect.bottom >= middleOfViewport;

      setIsHovered(isMiddle);
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div
      ref={cardRef}
      className={`border-black rounded-lg overflow-hidden bg-white transition-transform transform ${isHovered && 'scale-110 z-10 border-2'}`}
    >
      <img src={project.image} className="w-full h-64 object-cover" alt={project.name} />

      {isHovered && (
        <video
          src={project.video}
          className="absolute top-0 left-0 w-full h-64 object-cover"
          autoPlay
          loop
          muted
          playsInline
        />
      )}

      <div className="relative z-10">
        <h3 className="bg-zinc-100 text-center pt-0.5 px-2 text-2xl font-bold text-zinc-800">{project.name}</h3>
        <h4 className="bg-zinc-100 text-center pb-1 px-2 font-bold text-xs text-zinc-700">{project.role}</h4>
        <p className="w-full px-4 py-2">{project.description}</p>
        <div className="h-10 mt-2 mb-4">
          <a
            href={project.page}
            className="bg-yellow-300 hover:bg-yellow-400 transition w-48 text-center py-2 h-full px-4 rounded mx-auto block text-black"
          >
            View Page
          </a>
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
