import { useEffect, useRef } from 'react';

const ParticleDiv = ({ section, className, children }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    const createParticles = () => {
      if (section === null || section.id === 'Social Media')
        return;

      for (let i = 0; i < 4; i++) {
        const pageHeight = Math.max(document.body.scrollHeight, document.documentElement.scrollHeight, 
          document.body.offsetHeight, document.documentElement.offsetHeight,
          document.body.clientHeight, document.documentElement.clientHeight);

        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const viewportHeight = window.innerHeight;
        const sectionRect = section.getBoundingClientRect();
        const bottomOffset = -920;
        const bottomStart = pageHeight - (sectionRect.bottom + scrollTop) + viewportHeight + bottomOffset;


        const particle = document.createElement('div');
        const width = Math.random() * (section.id === 'Showcase' ? 0.3 : 0.20) + 0.04;
        particle.className = `absolute transition-opacity duration-200 -z-10 ${getRandomColor()}`;
        particle.style.left = `${Math.random() * containerRef.current.clientWidth}px`;
        particle.style.bottom = `${Math.random() * section.clientHeight + bottomStart}px`;
        particle.style.backgroundColor = getRandomColor();
        particle.style.opacity = '1';
        particle.style.width = `${width}rem`;
        particle.style.height = `${width}rem`;
      
        const randomX = (Math.random() * 2 - 1) * 65;
        const randomY = (Math.random() * 2 + (section.id === 'Showcase' ? 0.3 : -1)) * (section.id === 'Showcase' ? -70 : 65);
        const startRotation = Math.random() * 360;
        const endRotation = startRotation + Math.random() * 120 + 400;
      
        particle.style.setProperty('--end-x', `${randomX}px`);
        particle.style.setProperty('--end-y', `${randomY}px`);
        particle.style.setProperty('--start-rotation', `${startRotation}deg`);
        particle.style.setProperty('--end-rotation', `${endRotation}deg`);
      
        particle.style.animation = 'fall 1.0s linear forwards, fade 1.0s ease forwards';
        containerRef.current.appendChild(particle);
      
        particle.addEventListener('animationend', () => {
          particle.remove();
        });
      };    
    }

    const interval = setInterval(createParticles, 25);
    return () => clearInterval(interval);
  }, [section]);

  const getRandomColor = () => {
    const colors = ['bg-emerald-300', 'bg-teal-300', 'bg-cyan-300', 'bg-sky-300', 'bg-blue-300', 'bg-indigo-300', 'bg-cyan-500', 'bg-sky-500', 'bg-teal-500', 'bg-indigo-700'];
    return colors[Math.floor(Math.random() * colors.length)];
  };

  return (
    <div className={`${className} relative`}>
      <div
        className="absolute inset-0 overflow-hidden bg-white"
        ref={containerRef}
        style={{ pointerEvents: 'auto', zIndex: 0 }}
      />
      <div style={{ position: 'relative', zIndex: 1 }}>
        {children}
      </div>
    </div>
  );
};

export default ParticleDiv;
