import React from 'react';

const ErrorPage = () => {
  return (
    <main className="font-lexend">
        <h1 className="text-3xl mx-auto mt-20 text-center">Error</h1>
        <h2 className="text-lg mx-auto text-center">Page not found</h2>
    </main>
  );
};

export default ErrorPage;
